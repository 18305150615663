.af-view .w-layout-grid {
	display: -ms-grid;
	display: grid;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	grid-row-gap: 16px;
	grid-column-gap: 16px;
}

.af-view .w-checkbox {
	display: block;
	margin-bottom: 5px;
	padding-left: 20px;
}

.af-view .w-checkbox::before {
	content: " ";
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
}

.af-view .w-checkbox::after {
	content: " ";
	display: table;
	-ms-grid-column-span: 1;
	grid-column-end: 2;
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-row-span: 1;
	grid-row-end: 2;
	-ms-grid-row: 1;
	grid-row-start: 1;
	clear: both;
}

.af-view .w-checkbox-input {
	float: left;
	margin-bottom: 0px;
	margin-left: -20px;
	margin-right: 0px;
	margin-top: 4px;
	line-height: normal;
}

.af-view .w-checkbox-input--inputType-custom {
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	border-top-color: #ccc;
	border-bottom-color: #ccc;
	border-left-color: #ccc;
	border-right-color: #ccc;
	border-top-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	width: 12px;
	height: 12px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.af-view .w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	border-top-color: #3898ec;
	border-bottom-color: #3898ec;
	border-left-color: #3898ec;
	border-right-color: #3898ec;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.af-view .w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0px 0px 3px 1px #3898ec;
}

.af-view {
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 14px;
	line-height: 20px;
}

.af-view .navbar {
	margin-bottom: 0px;
	padding-bottom: 50px;
	background-color: #fff;
}

.af-view .navbar-logo-left-container {
	z-index: 5;
	width: 1030px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 15px 20px;
	background-color: transparent;
}

.af-view .navbar-logo-left-container.shadow-three {
	width: 100%;
	max-width: 1140px;
	margin-bottom: 0px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: transparent;
}

.af-view .container {
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 960px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 0px;
}

.af-view .container.calculator-container {
	display: block;
	height: 100%;
	padding: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .container.faq-select-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 80px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	grid-row-gap: 20px;
}

.af-view .container.extra-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-column-gap: 40px;
}

.af-view .container.header-info-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.af-view .container.footer-container {
	padding-bottom: 30px;
}

.af-view .navbar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .nav-menu-wrapper {
	color: #fff;
}

.af-view .nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: Montserrat, sans-serif;
	font-weight: 300;
}

.af-view .nav-link {
	display: block;
	overflow: visible;
	height: auto;
	margin-right: 5px;
	margin-left: 5px;
	padding: 5px 10px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px solid transparent;
	-webkit-transition: border 200ms ease;
	transition: border 200ms ease;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	text-decoration: none;
}

.af-view .nav-link:hover {
	display: block;
	border-bottom: 1px solid #fff;
	color: #fff;
}

.af-view .nav-link:focus-visible {
	border-radius: 4px;
	outline-color: #0050bd;
	outline-offset: 0px;
	outline-style: solid;
	outline-width: 2px;
	color: #0050bd;
}

.af-view .nav-link[data-wf-focus-visible] {
	border-radius: 4px;
	outline-color: #0050bd;
	outline-offset: 0px;
	outline-style: solid;
	outline-width: 2px;
	color: #0050bd;
}

.af-view .nav-link-accent {
	margin-right: 20px;
	margin-left: 15px;
	padding: 0px;
	border: 1px solid #fff;
	border-radius: 5px;
	font-family: Montserrat, sans-serif;
	color: #1a1b1f;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	letter-spacing: 0.25px;
	text-decoration: none;
}

.af-view .nav-link-accent:hover {
	color: rgba(26, 27, 31, 0.75);
}

.af-view .button {
	overflow: visible;
	width: auto;
	margin-top: 15px;
	padding: 18px 56px;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	border-radius: 6px;
	background-color: #b22427;
	-webkit-transition: background-color 100ms ease;
	transition: background-color 100ms ease;
	font-family: Montserrat, sans-serif;
	font-weight: 600;
	text-align: center;
	text-transform: none;
	white-space: normal;
}

.af-view .button:hover {
	background-color: #4b135f;
}

.af-view .button.button-green {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: #45db8a;
}

.af-view .button.button-green:hover {
	background-color: #4b135f;
}

.af-view .button.button-login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 0px;
	padding: 5px 16px;
	background-color: transparent;
	text-decoration: none;
}

.af-view .button.button-login:hover {
	background-color: #751a4b;
}

.af-view .button.arrow-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-top: 0px;
	margin-right: 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
}

.af-view .button.arrow-button.arrow-button-back {
	margin-left: 14px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.af-view .button.arrow-button.arrow-button-back:hover {
	background-color: #b22427;
}

.af-view .button.arrow-button.arrow-button-next {
	margin-right: 14px;
	background-color: #45db8a;
}

.af-view .button.courier-select-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20px;
	height: 20px;
	margin-top: 0px;
	padding: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #4b135f;
}

.af-view .button.summary-form-submit-button {
	width: auto;
	margin-top: 0px;
	padding: 10px 20px;
	background-color: #45db8a;
	font-weight: 600;
}

.af-view .button.button-simple {
	margin-top: 0px;
	padding: 0px;
	border-radius: 0px;
	background-color: transparent;
	color: #66646e;
	font-weight: 400;
}

.af-view .button.button-simple.button-simple-big {
	font-size: 22px;
}

.af-view .button.button-simple.button-simple-big.button-simple-big-selected {
	border-bottom: 1px solid #b22427;
	color: #b22427;
	font-weight: 700;
}

.af-view .button.button-simple.button-simple-small {
	font-size: 18px;
}

.af-view
	.button.button-simple.button-simple-small.button-simple-small-selected {
	border-bottom: 1px solid #66646e;
	font-weight: 700;
}

.af-view .button.button-outlined.button-outlined-small {
	width: auto;
	margin-top: 0px;
	padding: 8px 20px;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-color: #66646e;
}

.af-view .button.button-outlined.button-outlined-small:hover {
	border-color: #b22427;
	background-color: #b22427;
	color: #fff;
}

.af-view .button.button-centered {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 180px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 30px;
	padding-left: 30px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 10px;
	text-decoration: none;
}

.af-view .text-block {
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	text-align: right;
	text-decoration: none;
}

.af-view .menu-button-2 {
	position: absolute;
}

.af-view .header-pic {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 1;
	display: block;
	width: 100vw;
	height: 400px;
	max-width: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
	-o-object-fit: cover;
	object-fit: cover;
}

.af-view .heading-1 {
	margin-top: 130px;
	margin-bottom: 60px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 40px;
	text-align: center;
}

.af-view .header-info-value {
	font-family: Montserrat, sans-serif;
	color: #b22427;
	font-size: 22px;
	font-weight: 700;
}

.af-view .header-info {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	margin-top: 0px;
	padding: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	-webkit-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	text-align: center;
}

.af-view .header-info:hover {
	top: -10px;
	margin-top: 0px;
	padding-top: 0px;
	-webkit-transform: translate(0px, -10px);
	-ms-transform: translate(0px, -10px);
	transform: translate(0px, -10px);
}

.af-view .header-info-image {
	margin-bottom: 25px;
}

.af-view .header-info-description {
	font-family: Montserrat, sans-serif;
	color: #66646e;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
}

.af-view .image-3 {
	margin-right: 10px;
	padding-right: 0px;
}

.af-view .list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .logos-section {
	position: relative;
	padding: 80px 30px 100px;
	border-bottom: 0px none #e4ebf3;
	background-color: #fff;
}

.af-view .logos-section.logos-section-apps {
	padding-top: 60px;
	background-color: #f5f7fa;
}

.af-view .logos-section.logos-section-clients {
	display: block;
	padding-bottom: 40px;
	background-color: #fff;
}

.af-view .logos-section.logos-section-pos {
	padding-bottom: 0px;
}

.af-view .heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 750px;
	margin-right: auto;
	margin-bottom: 60px;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.af-view .heading-2 {
	margin-bottom: 40px;
	color: #4b135f;
	font-size: 38px;
	text-align: center;
}

.af-view .heading-2.heading-2-hero {
	margin-bottom: 60px;
}

.af-view .paragraph {
	margin-bottom: 0px;
	color: #66646e;
}

.af-view .companies-grid {
	position: relative;
	z-index: auto;
	margin-top: 0px;
	grid-auto-flow: row;
	grid-template-areas: ".";
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	-ms-grid-rows: 1fr;
	grid-template-rows: 1fr;
}

.af-view .companies-grid.companies-grid-small {
	-ms-grid-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-bottom: 2rem;
}

.af-view .kam-vas-napojime-man {
	position: absolute;
	left: 3%;
	top: auto;
	right: auto;
	bottom: 0%;
	z-index: 1;
}

.af-view .footer {
	position: relative;
	padding: 80px 0px 0px;
	border-bottom: 1px none #e4ebf3;
}

.af-view .footer-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	/* až bude newsletter, odkomentovat */
	/* -webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between; */
	grid-column-gap: 20px;
}

.af-view .footer-brand {
	margin-bottom: 30px;
}

.af-view .footer-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	/* až bude newsletter, zakomentovat */
	width: 50%;
}

.af-view .footer-block.footer-block-right {
	width: 40%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.af-view .footer-title {
	color: #b22427;
	font-size: 20px;
	line-height: 32px;
	font-weight: 700;
}

.af-view .footer-link {
	margin-top: 10px;
	color: #66646e;
	text-decoration: none;
}

.af-view .footer-link:hover {
	color: rgba(26, 27, 31, 0.75);
}

.af-view .footer-link.footer-link-big {
	margin-bottom: 10px;
	-webkit-transition: color 100ms ease;
	transition: color 100ms ease;
	color: #b9b9b9;
	font-size: 18px;
	text-decoration: none;
}

.af-view .footer-link.footer-link-big:hover {
	color: #707070;
}

.af-view .footer-form {
	width: 100%;
	max-width: none;
	margin-bottom: 0px;
}

.af-view .footer-form-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 24px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .footer-form-field {
	height: 48px;
	margin-right: 20px;
	margin-bottom: 0px;
	padding: 12px 16px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #a6b1bf #a6b1bf #707070;
	border-radius: 0px;
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
	font-size: 14px;
	line-height: 22px;
}

.af-view .footer-form-field:hover {
	border-style: none none solid;
	border-color: #be2629 #be2629 #707070;
}

.af-view .footer-form-field:focus {
	border-style: none none solid;
	border-color: #be2629;
}

.af-view .footer-form-field::-webkit-input-placeholder {
	color: rgba(26, 27, 31, 0.8);
	font-size: 14px;
	line-height: 22px;
}

.af-view .footer-form-field:-ms-input-placeholder {
	color: rgba(26, 27, 31, 0.8);
	font-size: 14px;
	line-height: 22px;
}

.af-view .footer-form-field::-ms-input-placeholder {
	color: rgba(26, 27, 31, 0.8);
	font-size: 14px;
	line-height: 22px;
}

.af-view .footer-form-field::placeholder {
	color: rgba(26, 27, 31, 0.8);
	font-size: 14px;
	line-height: 22px;
}

.af-view .footer-social-link {
	margin-left: 12px;
}

.af-view .footer-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.af-view .hero {
	position: relative;
	padding: 40px 30px 100px;
	border-bottom: 1px none #e4ebf3;
	background-color: #fff;
}

.af-view .hero-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.af-view .hero-split {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: 46%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.af-view .hero-split.hero-split-right {
	position: relative;
	width: 100%;
}

.af-view .hero-split.hero-split-left {
	width: 40%;
}

.af-view .hero-video {
	position: relative;
	z-index: 0;
	box-shadow: 0 4px 24px 0 rgba(150, 163, 181, 0.08);
}

.af-view .hero-description {
	color: #66646e;
}

.af-view .footer-copy {
	padding: 20px 10px;
	background-image: linear-gradient(32deg, #4b135f, #be2629);
	text-align: center;
}

.af-view .footer-copy-text {
	color: #fff;
}

.af-view .workflow-section {
	padding-top: 80px;
	padding-bottom: 80px;
}

.af-view .heading-3 {
	margin-top: 10px;
	color: #b22427;
	font-size: 22px;
	text-align: left;
}

.af-view .utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.af-view .utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.af-view .contact-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 40px;
}

.af-view .contact-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	max-width: 46%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.af-view .contact-column.contact-column-right {
	width: 60%;
	max-width: none;
}

.af-view .contact-column.contact-column-left {
	width: 40%;
	max-width: none;
}

.af-view .column-item-heading {
	color: #b22427;
	font-size: 22px;
	text-align: left;
}

.af-view .column-item-description {
	color: #66646e;
	font-weight: 400;
}

.af-view .pricing-section {
	position: relative;
	padding: 40px 30px 80px;
	border-bottom: 1px none #e4ebf3;
	background-color: #f5f7fa;
}

.af-view .pricing-wrapper {
	display: -ms-grid;
	display: grid;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	grid-auto-flow: row dense;
	grid-auto-columns: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 0px;
	-ms-grid-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
}

.af-view .pricing-card {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 32px 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 6px;
	background-color: #fff;
}

.af-view .pricing-card.pricing-card-2 {
	z-index: 2;
	padding-bottom: 42px;
	border-radius: 6px;
	background-image: linear-gradient(201deg, #be2629, #4b135f);
	color: #fff;
}

.af-view .pricing-image {
	height: 60px;
	margin-bottom: 16px;
	-o-object-fit: contain;
	object-fit: contain;
}

/* .af-view .pricing-image.pricing-image-web {
	width: 40px;
	height: 40px;
}

.af-view .pricing-image.pricing-image-dispatching {
	width: 80px;
	height: 80px;
} */

.af-view .pricing-title {
	margin-top: 10px;
	margin-bottom: 8px;
	color: #b22427;
	font-size: 22px;
	line-height: 27px;
	font-weight: 700;
}

.af-view .pricing-title.pricing-title-featured {
	color: #fff;
}

.af-view .pricing-subtitle {
	margin-bottom: 12px;
	font-size: 13px;
	line-height: 20px;
}

.af-view .pricing-price {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 8px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
}

.af-view .pricing-feature-list {
	margin-bottom: 30px;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
}

.af-view .pricing-feature {
	margin-top: 16px;
	padding-left: 32px;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	background-image: url("../../public/images/check.svg");
	background-position: 0% 50%;
	background-size: auto;
	background-repeat: no-repeat;
	color: #66646e;
	font-size: 14px;
	line-height: 24px;
}

.af-view .pricing-feature.pricing-feature-featured {
	color: #fff;
}

.af-view .pricing-tag {
	position: absolute;
	left: auto;
	top: -10px;
	right: 10px;
	bottom: auto;
	padding: 7px 16px;
	border-radius: 0px 6px 6px;
	background-color: #4b135f;
	box-shadow: 0 3px 10px 0 rgba(150, 163, 181, 0.2);
	color: #fff;
}

.af-view .pricing-tag.pricing-tag-top {
	top: -10px;
	bottom: auto;
}

.af-view .pricing-tag.pricing-tag-bottom {
	top: auto;
	bottom: -10px;
	padding-right: 7px;
	padding-left: 7px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 0px;
}

.af-view .pricing-price-value {
	font-size: 32px;
}

.af-view .pricing-price-unit {
	margin-left: 5px;
	font-size: 17px;
	font-weight: 300;
}

.af-view .button-outlined {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border: 1px solid #66646e;
	border-radius: 6px;
	background-color: transparent;
	-webkit-transition: opacity 100ms ease;
	transition: opacity 100ms ease;
	color: #66646e;
	font-weight: 600;
	text-align: center;
}

.af-view .button-outlined:hover {
	border-color: #4b135f;
	color: #4b135f;
}

.af-view .button-outlined.button-outlined-white {
	border-color: #fff;
	color: #fff;
}

.af-view .button-outlined.footer-button-outlined {
	width: auto;
	padding: 5px 25px;
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
}

.af-view .button-outlined.footer-button-outlined:hover {
	border-color: #707070;
	color: #707070;
}

.af-view .carousel-item-logo-container {
	position: absolute;
	top: 210px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100px;
	height: 100px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100%;
	background-color: #fff;
}

.af-view .carousel-item-logo {
	width: 60px;
	height: 60px;
}

.af-view .slide-container {
	padding: 10px;
	background-color: #fff;
}

.af-view .carousel-item-text-wrapper {
	width: 100%;
	height: 100%;
	padding: 75px 50px 40px;
	color: #66646e;
	text-align: center;
}

.af-view .grid {
	width: 80%;
	grid-column-gap: 46px;
	grid-row-gap: 72px;
	-ms-grid-columns: 40px 1fr;
	grid-template-columns: 40px 1fr;
	-ms-grid-rows: auto auto auto auto;
	grid-template-rows: auto auto auto auto;
}

.af-view .contact-box {
	position: relative;
	width: 100%;
	padding: 45px 60px;
	border-radius: 6px;
	background-image: linear-gradient(212deg, #be2629, #4b135f);
}

.af-view .contact-text {
	color: #fff;
	font-size: 18px;
	font-weight: 300;
	width: max-content;
}

.af-view .contact-section {
	padding-top: 40px;
}

.af-view .contact-section--separate {
	margin-top: 80px;
}

.af-view .contact-woman {
	position: absolute;
	left: auto;
	top: auto;
	right: -9%;
	bottom: 0%;
}

.af-view .form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.af-view .contact-form-field {
	display: block;
	margin-bottom: 20px;
	border-style: none none solid;
	border-width: 0px 0px 1px;
	border-color: #000 #000 #707070;
	-webkit-transition: border-color 100ms ease;
	transition: border-color 100ms ease;
	text-align: left;
	mix-blend-mode: normal;
}

.af-view .contact-form-field:focus {
	border-bottom-color: #b22427;
}

.af-view .form-block {
	width: 100%;
}

.af-view .footer-social-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .company-logo {
	max-width: 200px;
	position: static;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.af-view .company-logo:hover {
	position: static;
	top: -10px;
	-webkit-transform: translate(0px, -10px);
	-ms-transform: translate(0px, -10px);
	transform: translate(0px, -10px);
}

.af-view .divider-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .divider {
	width: 120px;
	height: 5px;
	border-radius: 20px;
	background-color: #b22427;
}

.af-view .calculator-section {
	padding-top: 30px;
	padding-bottom: 80px;
	background-color: #fff;
}

.af-view .calculator-grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	-ms-grid-columns: 1fr 2fr 1fr;
	grid-template-columns: 1fr 2fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
}

.af-view .calculator-center {
	width: 60%;
	height: 100%;
	min-height: 500px;
	border-radius: 6px;
	box-shadow: 0 0 8px 2px rgba(99, 99, 99, 0.2);
}

.af-view .calculator-side {
	height: 400px;
	padding: 30px 20px;
	background-image: linear-gradient(50deg, #4b135f, #b22427);
}

.af-view .calculator-side.calculator-side-left {
	width: 20%;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.af-view .calculator-side.calculator-side-right {
	width: 20%;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.af-view .calculator-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-row-gap: 40px;
	color: #fff;
}

.af-view .calculator-list.calculator-price-list {
	padding-left: 20px;
}

.af-view .paragraph-2 {
	margin-bottom: 40px;
	color: #707070;
	text-align: center;
}

.af-view .calculator-item.calculator-price-item {
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	text-align: right;
}

.af-view .calculator-item.calculator-price-item.calculator-price-item-heading {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.af-view .calculator-item.calculator-price-item.calculator-price-item-final {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
}

.af-view
	.calculator-item.calculator-content-list-item.calculator-content-list-item-7 {
	display: none;
}

.af-view .calculator-final-price {
	display: inline-block;
	margin-bottom: 0px;
	font-size: 24px;
	font-weight: 700;
}

.af-view .bold-text {
	text-align: left;
}

.af-view .calculator-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .calculator-content.calculator-content-1 {
	display: none;
}

.af-view .calculator-content.calculator-content-2 {
	display: none;
}

.af-view .calculator-content.calculator-content-3 {
	display: none;
}

.af-view .calculator-content.calculator-content-4 {
	display: none;
}

.af-view .calculator-content.calculator-content-5 {
	display: none;
}

.af-view .calculator-content.calculator-content-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.af-view .calculator-content.calculator-content-6.calculator-content-6-2 {
	display: none;
}

.af-view .select-platforms-grid {
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-template-areas: ".";
	-ms-grid-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.af-view .toggle-button {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 36px;
	height: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 12px;
	background-color: #f0f0f0;
	text-decoration: none;
}

.af-view .toggle-button-fill {
	position: absolute;
	width: 36px;
	height: 20px;
	border-radius: 12px;
	opacity: 0;
}

.af-view .button-toggle {
	position: relative;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 16px;
	height: 16px;
	margin-right: 4px;
	margin-left: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100%;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
		0 1px 3px 0 rgba(16, 24, 40, 0.1);
}

.af-view .calculator-navigation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view
	.calculator-navigation.calculator-navigation-next.calculator-navigation-next-6.calculator-navigation-next-6-2 {
	display: none;
}

.af-view .calculator-navigations {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-top: 30px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .arrow-button-icon {
	width: 14px;
}

.af-view .calculator-navigation-text {
	font-weight: 600;
}

.af-view .calculator-navigation-text.calculator-navigation-text-back {
	color: #b22427;
}

.af-view .calculator-navigation-text.calculator-navigation-text-next {
	color: #45db8a;
}

.af-view .courier-select-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .courier-select-number {
	margin-right: 8px;
	margin-left: 8px;
	padding-left: 0px;
	font-size: 36px;
	font-weight: 700;
}

.af-view .select-dispatching-grid {
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.af-view .select-dispatching-option {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .heading-4 {
	color: #66646e;
	font-size: 16px;
}

.af-view .heading-4.select-dispatching-option-heading {
	text-align: center;
}

.af-view .heading-4.select-pos-option-heading {
	text-align: center;
}

.af-view .select-dispatching-option-description {
	color: #66646e;
	text-align: center;
}

.af-view .form-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .select-pos-grid {
	justify-items: center;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
}

.af-view .summary-form-grid {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-grid-rows: auto auto auto auto auto;
	grid-template-rows: auto auto auto auto auto;
}

.af-view .summary-form-input {
	margin-bottom: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 #707070;
	-webkit-transition: border-color 100ms ease;
	transition: border-color 100ms ease;
}

.af-view .summary-form-input:focus {
	border-bottom-color: #b22427;
}

.af-view .summary-form-input.faq-search-input {
	width: 220px;
	padding-left: 12px;
}

.af-view .summary-form-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 12px;
	padding-left: 12px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 30px;
	border-bottom: 1px solid #707070;
}

.af-view .summary-form-item-text {
	color: #66646e;
}

.af-view .summary-form-item-value {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	color: #66646e;
	text-align: right;
}

.af-view .summary-form-item-value-picture {
	width: 30px;
	height: 30px;
	margin-left: 5px;
}

.af-view .summary-form-item-value-edit-button {
	margin-left: 10px;
}

.af-view .link-block-2 {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view .faq-answer-text {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	color: #66646e;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 400;
	width: calc(100% - 300px);
}

@media screen and (max-width: 768px) {
	.af-view .faq-answer-text {
		width: 100%;
	}
}

.af-view .faq-answer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding: 20px 55px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	grid-column-gap: 40px;
}

.af-view .faq-answer-wrapper {
	display: block;
	overflow: hidden;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 60px;
}

.af-view .faq-question-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
	color: #66646e;
}

.af-view .faq-question-arrow.faq-question-arrow-disabled {
	opacity: 0;
}

.af-view .faq-question-arrow-wrapper {
	display: block;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	opacity: 1;
}

.af-view .faq-question-text {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: Montserrat, sans-serif;
	color: #66646e;
	font-size: 18px;
	line-height: 22px;
	font-weight: 400;
	letter-spacing: normal;
}

.af-view .faq-question {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	border-top: 1px solid #ddd;
	cursor: pointer;
}

.af-view .faq-question.faq-question-disabled {
	cursor: default;
}

.af-view .faq-accordion {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-bottom: 1px none #000;
}

.af-view .faq-list {
	border-bottom: 1px solid #ddd;
}

.af-view .faq {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .faq-section {
	padding-bottom: 40px;
	background-color: #fff;
}

.af-view .faq-answer-image {
	width: 100%;
	max-width: 300px;
}

.af-view .faq-question-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	align-self: center;
	grid-column-gap: 16px;
}

.af-view .categories {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .categories-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 20px;
}

.af-view .subcategories-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	grid-column-gap: 18px;
}

.af-view .categories-search {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view .faq-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 10px;
}

.af-view .faq-form-wrapper {
	height: 100%;
	margin-bottom: 0px;
}

.af-view .faq-select {
	padding-top: 40px;
	padding-bottom: 40px;
}

.af-view .hero-heading-left {
	position: relative;
	padding: 80px 30px;
	border-bottom: 0px solid #e4ebf3;
	background-color: transparent;
}

.af-view .hero-heading-left:first-of-type {
	padding-top: 140px;
}

.af-view .hero-wrapper-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .pricing-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 46%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.af-view .margin-bottom-24px {
	margin-bottom: 24px;
}

.af-view .faq-question-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .faq-question-right-value {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #b22427;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
}

.af-view .faq-description {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.af-view .faq-question-right-value-image {
	height: 16px;
	max-width: none;
	color: #9aa;
}

.af-view .extra-section {
	padding-top: 40px;
	text-align: center;
}

.af-view .extra {
	width: 100%;
}

.af-view .extra-grid-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 20px 30px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #66646e;
}

.af-view .extra-grid-item.extra-grid-item-left {
	padding: 15px 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
}

.af-view .extra-grid-item.extra-grid-item-right {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: #b22427;
	text-align: right;
}

.af-view .grid-2 {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.af-view .header-info-section {
	position: relative;
	top: -15px;
}

.af-view .slider {
	width: 100%;
	height: max-content;
	margin-top: 40px;
}

.af-view .slide-image {
	width: 100%;
	max-height: 250px;
	max-width: none;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	-o-object-fit: cover;
	object-fit: cover;
}

.af-view .workflow-grid {
	justify-items: stretch;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-column-gap: 80px;
	grid-row-gap: 20px;
}

.af-view .workflow-grid-item.workflow-grid-item-image {
	height: 300px;
}

.af-view .slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	box-shadow: 0 0 6px 1px #e4e4e4;
}

.af-view .slide-nav {
	bottom: -50px;
	display: block;
}

.af-view .mask {
	overflow: visible;
}

.af-view .left-arrow {
	display: none;
}

.af-view .right-arrow {
	display: none;
}

.af-view .dashed-line {
	position: absolute;
	width: 100%;
	height: 0px;
	border-bottom: 1px dashed #707070;
}

.af-view .dashed-line.dashed-line-1 {
	left: 286px;
	top: 370px;
	width: 300px;
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

.af-view .dashed-line.dashed-line-2 {
	left: 333px;
	top: 722px;
	width: 300px;
	-webkit-transform: rotate(-30deg);
	-ms-transform: rotate(-30deg);
	transform: rotate(-30deg);
}

.af-view .dashed-line.dashed-line-3 {
	left: 369px;
	top: 1093px;
	width: 200px;
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

.af-view .tablet-image {
	position: absolute;
	top: 32px;
	z-index: 0;
	display: block;
	-webkit-transform: scale(1.28);
	-ms-transform: scale(1.28);
	transform: scale(1.28);
}

.af-view .pricing-slider {
	display: none;
}

.af-view .checkbox {
	width: 20px;
	height: 20px;
	margin-top: 0px;
	margin-right: 10px;
	border-radius: 0px;
	font-size: 16px;
}

.af-view .checkbox-label {
	font-size: 12px;
}

.af-view .checkbox-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.af-view .button-2 {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.af-view .text-block-2 {
	border-bottom: 1px none #000;
	color: #fff;
	text-decoration: none;
}

.af-view .image-9 {
	width: 16px;
	height: 16px;
}

@media screen and (min-width: 1280px) {
	.af-view .container {
		max-width: 1000px;
	}

	.af-view .header-pic {
		height: 340px;
	}

	.af-view .logos-section.logos-section-clients {
		background-color: #fff;
	}

	.af-view .kam-vas-napojime-man {
		left: 4%;
	}

	.af-view .hero {
		padding-bottom: 120px;
	}

	.af-view .pricing-price-value {
		font-size: 35px;
	}

	.af-view .pricing-price-unit {
		margin-left: 5px;
	}

	.af-view .service-image {
		box-shadow: none;
	}

	.af-view .header-info-section {
		top: -70px;
	}

	.af-view .dashed-line.dashed-line-1 {
		left: 295px;
		top: 390px;
		width: 300px;
		-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
		transform: rotate(30deg);
	}

	.af-view .dashed-line.dashed-line-2 {
		left: 353px;
		width: 300px;
	}

	.af-view .dashed-line.dashed-line-3 {
		left: 388px;
		top: 1099px;
		width: 200px;
	}
	.af-view .container.faq-select-container {
		padding-top: 40px;
	}
}

@media screen and (min-width: 1440px) {
	.af-view .container {
		max-width: 1200px;
	}

	.af-view .container.header-info-container {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.af-view .header-pic {
		height: auto;
		height: 390px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.af-view .kam-vas-napojime-man {
		left: 12%;
	}

	.af-view .hero {
		padding-bottom: 222px;
	}

	.af-view .hero-split.hero-split-right {
		max-width: 42%;
	}

	.af-view .contact-woman {
		right: -9%;
	}

	.af-view .header-info-section {
		margin-top: 40px;
	}

	.af-view .slider {
		overflow: visible;
		width: 100%;
		background-color: transparent;
	}

	.af-view .dashed-line.dashed-line-1 {
		left: 360px;
		top: 402px;
		width: 400px;
		-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
		transform: rotate(30deg);
	}

	.af-view .dashed-line.dashed-line-2 {
		left: 433px;
		top: 727px;
		width: 350px;
	}

	.af-view .dashed-line.dashed-line-3 {
		left: 473px;
		top: 1085px;
		width: 250px;
	}

	.af-view .tablet-image {
		top: 37px;
		bottom: auto;
	}
}

@media screen and (max-width: 991px) {
	.af-view .container {
		max-width: 720px;
	}

	.af-view .container.faq-select-container {
		padding-top: 40px;
	}

	.af-view .navbar-wrapper {
		padding-top: 10px;
	}

	.af-view .nav-menu-wrapper {
		background-color: transparent;
	}

	.af-view .nav-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		padding: 20px;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 50px;
		background-color: #fff;
		box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
		color: #b22427;
	}

	.af-view .nav-link {
		padding-right: 5px;
		padding-left: 5px;
	}

	.af-view .menu-button {
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		padding: 12px;
		color: #fff;
	}

	.af-view .menu-button.w--open {
		background-color: transparent;
		color: #fff;
	}

	.af-view .header-pic {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: block;
		width: 100vw;
		height: 300px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.af-view .heading-1 {
		margin-top: 80px;
		margin-bottom: 40px;
	}

	.af-view .heading-2 {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .heading-2.heading-2-hero {
		font-size: 34px;
	}

	.af-view .kam-vas-napojime-man {
		display: none;
	}

	.af-view .footer-wrapper {
		/* až bude newsletter, odkomentovat */
		/* -webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap; */
	}

	.af-view .footer-form {
		width: 100%;
		margin-top: 0px;
	}

	.af-view .footer-form-container {
		max-width: 350px;
	}

	.af-view .hero {
		padding-bottom: 200px;
	}

	.af-view .hero-wrapper {
		margin-bottom: -40px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.af-view .hero-split {
		max-width: 100%;
		margin-bottom: 40px;
	}

	.af-view .hero-split.hero-split-right {
		width: 60%;
		max-width: 60%;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .hero-split.hero-split-left {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.af-view .hero-video {
		position: static;
		width: 80%;
	}

	.af-view .contact-wrapper {
		margin-bottom: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.af-view .contact-column {
		max-width: 100%;
		margin-bottom: 40px;
	}

	.af-view .contact-column.contact-column-right {
		width: 50%;
	}

	.af-view .contact-column.contact-column-left {
		width: 50%;
	}

	.af-view .pricing-section {
		padding-right: 0px;
		padding-left: 0px;
	}

	.af-view .pricing-wrapper {
		justify-items: center;
		grid-row-gap: 20px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.af-view .pricing-card {
		width: 350px;
	}

	.af-view .grid {
		width: 100%;
		justify-items: start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		grid-column-gap: 30px;
		grid-row-gap: 40px;
	}

	.af-view .contact-box {
		padding: 40px;
	}

	.af-view .contact-woman {
		display: none;
	}

	.af-view .calculator-grid {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.af-view .calculator-center {
		width: 100%;
		min-height: 400px;
	}

	.af-view .calculator-side.calculator-side-left {
		width: 80%;
		height: 60px;
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		border-top-right-radius: 6px;
		border-bottom-left-radius: 0px;
	}

	.af-view .calculator-side.calculator-side-right {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 80%;
		height: 60px;
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 6px;
	}

	.af-view .calculator-list.calculator-content-list {
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .calculator-list.calculator-price-list {
		width: 100%;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .calculator-item.calculator-price-item {
		display: none;
	}

	.af-view
		.calculator-item.calculator-price-item.calculator-price-item-heading {
		display: block;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view
		.calculator-item.calculator-price-item.calculator-price-item-final {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		align-self: auto;
		grid-column-gap: 10px;
		grid-row-gap: 0px;
	}

	.af-view .calculator-item.calculator-content-list-item {
		display: none;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.af-view
		.calculator-item.calculator-content-list-item.calculator-content-list-item-1 {
		display: none;
	}

	.af-view
		.calculator-item.calculator-content-list-item.calculator-content-list-item-2 {
		display: none;
	}

	.af-view
		.calculator-item.calculator-content-list-item.calculator-content-list-item-7 {
		display: none;
	}

	.af-view .calculator-final-price {
		display: block;
		margin-bottom: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.af-view .calculator-content.calculator-content-1 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-2 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-3 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-4 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-5 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-6 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-6.calculator-content-6-1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.af-view .summary-form-input.faq-search-input {
		width: 220px;
	}

	.af-view .faq-answer {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.af-view .categories {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.af-view .categories-buttons {
		width: 100%;
	}

	.af-view .categories-search {
		width: auto;
		margin-bottom: 20px;
		-webkit-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.af-view .hero-heading-left {
		padding-top: 40px;
		padding-bottom: 60px;
	}

	.af-view .hero-heading-left:first-of-type {
		padding-top: 80px;
	}

	.af-view .hero-wrapper-3 {
		margin-bottom: -40px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.af-view .pricing-heading {
		max-width: 100%;
		margin-bottom: 30px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.af-view .service-image {
		max-height: 300px;
	}

	.af-view .header-info-section {
		top: 0px;
	}

	.af-view .slide-image {
		height: 250px;
		max-height: none;
	}

	.af-view .dashed-line.dashed-line-1 {
		left: 210px;
		top: 362px;
		width: 220px;
	}

	.af-view .dashed-line.dashed-line-2 {
		left: 257px;
		top: 706px;
		width: 220px;
	}

	.af-view .dashed-line.dashed-line-3 {
		left: 245px;
		top: 980px;
		width: 200px;
	}

	.af-view .pricing-slider-nav {
		display: none;
	}

	.af-view .company-logo {
		max-width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.af-view .navbar-logo-left-container {
		max-width: 100%;
	}

	.af-view .container {
		max-width: 440px;
	}

	.af-view .container.extra-container {
		max-width: 80%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .navbar-wrapper {
		padding-top: 7px;
	}

	.af-view .navbar-brand {
		padding-left: 0px;
	}

	.af-view .nav-menu {
		border-radius: 100px;
		padding: 10px;
	}

	.af-view .nav-link {
		display: inline-block;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.af-view .nav-link-accent {
		display: inline-block;
		margin-right: 5px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.af-view .menu-button {
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		color: #fff;
	}

	.af-view .button.summary-form-submit-button.summary-form-submit-button-6-1 {
		display: none;
	}

	.af-view .header-pic {
		height: 240px;
	}

	.af-view .heading-1 {
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 32px;
	}

	.af-view .logos-section {
		padding: 60px 15px;
	}

	.af-view .heading-2 {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .heading-2.heading-2-hero {
		font-size: 32px;
	}

	.af-view .footer {
		padding-right: 0px;
		padding-left: 0px;
	}

	.af-view .footer-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		grid-column-gap: 0px;
	}

	.af-view .footer-block {
		width: 50%;
		grid-column-gap: 0px;
	}

	.af-view .footer-block.footer-block-right {
		width: 100%;
	}

	.af-view .footer-form {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.af-view .footer-form-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: none;
	}

	.af-view .footer-social-link {
		margin-left: 0px;
	}

	.af-view .hero {
		padding: 60px 15px 160px;
	}

	.af-view .hero-split.hero-split-right {
		width: 80%;
		max-width: 80%;
	}

	.af-view .heading-3 {
		font-size: 20px;
		text-align: center;
	}

	.af-view .heading-3.faq-question-right-value-heading {
		font-size: 16px;
	}

	.af-view .contact-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .contact-column.contact-column-right {
		width: 100%;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.af-view .contact-column.contact-column-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .pricing-section {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.af-view .pricing-wrapper {
		display: none;
		justify-items: stretch;
	}

	.af-view .pricing-card {
		width: auto;
		height: 500px;
	}

	.af-view .pricing-card.pricing-card-1 {
		height: 500px;
	}

	.af-view .pricing-title {
		font-size: 34px;
		line-height: 42px;
	}

	.af-view .carousel-item-logo-container {
		top: 170px;
		width: 80px;
		height: 80px;
	}

	.af-view .carousel-item-logo {
		width: 50px;
		height: 50px;
	}

	.af-view .contact-box {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.af-view .contact-text {
		font-size: 18px;
	}

	.af-view .footer-social-links {
		margin-top: 20px;
		grid-column-gap: 20px;
	}

	.af-view
		.calculator-item.calculator-content-list-item.calculator-content-list-item-6 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-1 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-2 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-3 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-4 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-5 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-6 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.af-view .calculator-content.calculator-content-6.calculator-content-6-1 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-6.calculator-content-6-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.af-view .toggle-button-fill {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.af-view
		.calculator-navigation.calculator-navigation-next.calculator-navigation-next-6.calculator-navigation-next-6-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.af-view .calculator-navigations {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		justify-content: space-around;
	}

	.af-view .form-block-2 {
		width: 100%;
	}

	.af-view .select-pos-grid {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.af-view .summary-form {
		width: 100%;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .summary-form-grid {
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.af-view .summary-form-grid.summary-form-grid-6-2 {
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.af-view .summary-form-input.faq-search-input {
		width: 100%;
	}

	.af-view .summary-form-input.summary-form-input-1 {
		display: none;
	}

	.af-view .summary-form-item {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.af-view .summary-form-item.summary-form-item-2 {
		display: none;
	}

	.af-view .faq-answer {
		margin-bottom: 1.25rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .faq-question-arrow {
		width: 1.75rem;
		height: 1.75rem;
	}

	.af-view .faq-question-arrow-wrapper {
		width: 1.75rem;
	}

	.af-view .faq-question-text {
		font-size: 14px;
	}

	.af-view .faq-question {
		overflow: hidden;
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.af-view .faq-accordion {
		width: 100%;
	}

	.af-view .faq-list {
		width: 100%;
	}

	.af-view .faq-answer-image {
		width: 70%;
		height: auto;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.af-view .categories {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .categories-buttons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		grid-row-gap: 10px;
	}

	.af-view .subcategories-buttons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.af-view .categories-search {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 26px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.af-view .faq-form {
		width: 100%;
	}

	.af-view .faq-form-wrapper {
		width: 100%;
	}

	.af-view .hero-heading-left {
		padding: 60px 15px;
	}

	.af-view .hero-wrapper-3 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .margin-bottom-24px {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .service-image {
		height: 300px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .faq-question-right-value {
		width: 120px;
		font-size: 16px;
	}

	.af-view .faq-question-right-value-image {
		max-height: 14px;
	}

	.af-view .extra {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .slide-image {
		overflow: visible;
		height: 200px;
		max-height: none;
	}

	.af-view .workflow-grid {
		display: -ms-grid;
		display: grid;
		justify-items: center;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.af-view .workflow-grid-item.workflow-grid-item-image {
		max-height: 200px;
		max-width: 100%;
	}

	.af-view .dashed-line.dashed-line-1 {
		display: none;
	}

	.af-view .dashed-line.dashed-line-2 {
		display: none;
	}

	.af-view .dashed-line.dashed-line-3 {
		display: none;
	}

	.af-view .form-2 {
		width: 100%;
	}

	.af-view .tablet-image {
		top: 27px;
		bottom: 27px;
	}

	.af-view .pricing-slider {
		display: block;
		height: 500px;
		border-radius: 5px;
		background-color: transparent;
	}

	.af-view .pricing-slider-slide-container {
		height: 500px;
		padding-right: 20px;
		padding-left: 20px;
		border-radius: 5px;
	}

	.af-view .mask-2 {
		overflow: visible;
		height: 500px;
		border-radius: 5px;
	}

	.af-view .pricing-slider-arrow {
		left: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30px;
		height: 30px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 1px solid #f4f4f4;
		border-radius: 50%;
		background-color: #fff;
	}

	.af-view .pricing-slider-arrow.pricing-slider-arrow-left {
		left: 5px;
	}

	.af-view .pricing-slider-arrow.pricing-slider-arrow-right {
		left: auto;
		right: 5px;
	}

	.af-view .pricing-slider-arrow-right {
		right: -38px;
	}

	.af-view .pricing-slider-arrow-icon {
		position: static;
		padding: 0px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		color: #e4e4e4;
		font-size: 20px;
		text-align: left;
	}

	.af-view .companies-grid.companies-grid-small {
		grid-row-gap: 0px;
		grid-template-columns: 1fr 1fr;
	}

	.af-view .company-logo {
		max-width: 80%;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 479px) {
	.af-view .navbar {
		padding-bottom: 20px;
	}

	.af-view .container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.af-view .container.header-info-container {
		position: static;
	}

	.af-view .container.calculator-container {
		padding: 15px;
	}

	.af-view .container.faq-select-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-top: 20px;
	}

	.af-view .container.extra-container {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.af-view .container.footer-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.af-view .container.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.af-view .navbar-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 12px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.af-view .navbar-brand {
		height: auto;
	}

	.af-view .nav-menu-wrapper {
		position: absolute;
		z-index: 20 !important;
		left: auto;
		top: 0%;
		left: 0 !important;
		right: 0%;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100% !important;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0px 20px;
		height: max-content;
	}

	.af-view .nav-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
		color: #4b135f;
		position: relative;
		z-index: 20 !important;
		border-radius: 20px;
	}

	.af-view .nav-link {
		color: #4b135f;
	}

	.af-view .nav-link-accent {
		border-style: none;
		margin-top: 10px;
		margin-left: 5px;
	}

	.af-view .menu-button {
		right: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		color: #fff;
	}

	.af-view .button {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .button.button-login:hover {
		color: #fff;
	}

	.af-view .button.button-simple.button-simple-big {
		font-size: 20px;
	}

	.af-view .header-pic {
		height: 200px;
	}

	.af-view .heading-1 {
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 22px;
	}

	.af-view .header-info-image {
		display: none;
	}

	.af-view .image-3 {
		color: #751a4b;
	}

	.af-view .logos-section.logos-section-apps {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.af-view .logos-section.logos-section-pos {
		padding-top: 20px;
		padding-bottom: 40px;
	}

	.af-view .heading-wrapper {
		margin-bottom: 20px;
	}

	.af-view .heading-2 {
		margin-bottom: 20px;
		font-size: 25px;
	}

	.af-view .heading-2.heading-2-hero {
		margin-bottom: 20px;
		font-size: 26px;
	}

	.af-view .companies-grid.companies-grid-small {
		grid-row-gap: 0px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.af-view .kam-vas-napojime-man {
		display: none;
	}

	.af-view .footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 40px;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.af-view .footer-wrapper {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .footer-block {
		width: 100%;
		margin-top: 40px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.af-view .footer-block.footer-block-right {
		width: 100%;
	}

	.af-view .footer-title {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .footer-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.af-view .footer-form-block {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.af-view .footer-form-field {
		width: 75%;
		margin-right: 0px;
		text-align: center;
	}

	.af-view .footer-social-link {
		margin-left: 0px;
	}

	.af-view .hero {
		padding-top: 20px;
		padding-bottom: 60px;
		padding-left: 15px;
	}

	.af-view .hero-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .hero-split.hero-split-right {
		width: 100%;
		max-width: 100%;
	}

	.af-view .hero-split.hero-split-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.af-view .workflow-section {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.af-view .heading-3 {
		font-size: 16px;
		text-align: center;
	}

	.af-view .heading-3.faq-question-right-value-heading {
		position: relative;
		top: -40px;
		margin-top: 0px;
		margin-bottom: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		line-height: 20px;
		text-align: center;
	}

	.af-view .contact-wrapper {
		margin-top: -20px;
	}

	.af-view .contact-column.contact-column-left {
		width: 100%;
		margin-bottom: 0px;
	}

	.af-view .column-item-heading {
		text-align: center;
	}

	.af-view .column-item-description {
		text-align: center;
	}

	.af-view .pricing-section {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.af-view .pricing-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		grid-auto-flow: row;
		grid-column-gap: 0px;
		grid-row-gap: 20px;
	}

	.af-view .pricing-card {
		overflow: visible;
		width: 100%;
	}

	.af-view .button-outlined.footer-button-outlined {
		margin-top: 30px;
	}

	.af-view .carousel-item-logo-container {
		top: 150px;
		overflow: visible;
		height: 80px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		-o-object-fit: fill;
		object-fit: fill;
	}

	.af-view .slide-container {
		overflow: hidden;
	}

	.af-view .carousel-item-text-wrapper {
		width: 100%;
		height: auto;
		padding-top: 60px;
		padding-right: 30px;
		padding-left: 30px;
	}

	.af-view .contact-box {
		display: none;
	}

	.af-view .contact-section {
		padding-top: 20px;
	}

	.af-view .contact-woman {
		display: none;
	}

	.af-view .footer-social-links {
		margin-top: 20px;
		-ms-grid-row-align: center;
		align-self: center;
		grid-column-gap: 20px;
	}

	.af-view .calculator-center {
		border-radius: 0px;
	}

	.af-view .calculator-side.calculator-side-left {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 50px;
		padding: 0px 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .calculator-side.calculator-side-right {
		width: 100%;
		height: 50px;
		padding: 10px;
	}

	.af-view
		.calculator-item.calculator-price-item.calculator-price-item-final {
		grid-column-gap: 5px;
	}

	.af-view .calculator-final-price {
		font-size: 16px;
	}

	.af-view .calculator-content.calculator-content-1 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-2 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-3 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-4 {
		display: none;
	}

	.af-view .calculator-content.calculator-content-5 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.af-view .calculator-content.calculator-content-6.calculator-content-6-2 {
		display: none;
	}

	.af-view .calculator-navigations {
		width: 100%;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.af-view .select-dispatching-grid {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		-ms-grid-columns: 3fr 1fr;
		grid-template-columns: 3fr 1fr;
	}

	.af-view .select-dispatching-option {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.af-view .heading-4.select-pos-option-heading {
		text-align: left;
	}

	.af-view .select-dispatching-option-description {
		text-align: left;
	}

	.af-view .select-pos-grid {
		justify-items: start;
		grid-column-gap: 8px;
		grid-row-gap: 0px;
		-ms-grid-columns: 3fr 1fr;
		grid-template-columns: 3fr 1fr;
	}

	.af-view .select-pos-option {
		text-align: left;
	}

	.af-view .summary-form-input.faq-search-input {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
	}

	.af-view .faq-answer-text {
		width: 100%;
	}

	.af-view .faq-answer {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		grid-column-gap: 0px;
		grid-row-gap: 20px;
	}

	.af-view .faq-question-text {
		max-width: 100px;
	}

	.af-view .faq-question {
		width: 100%;
	}

	.af-view .faq-accordion {
		width: 100%;
	}

	.af-view .faq-list {
		width: 100%;
	}

	.af-view .faq-answer-image {
		width: 80%;
		height: auto;
	}

	.af-view .faq-question-left {
		width: auto;
	}

	.af-view .categories {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.af-view .categories-buttons {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		grid-row-gap: 10px;
	}

	.af-view .subcategories-buttons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		grid-row-gap: 10px;
	}

	.af-view .categories-search {
		width: 100%;
		margin-bottom: 20px;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.af-view .faq-form {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.af-view .faq-form-wrapper {
		width: 100%;
	}

	.af-view .faq-select {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.af-view .faq-search-icon {
		display: none;
	}

	.af-view .hero-heading-left {
		padding-top: 20px;
		padding-bottom: 60px;
	}

	.af-view .hero-wrapper-3 {
		margin-bottom: 0px;
	}

	.af-view .service-image {
		width: 100%;
		height: auto;
		max-height: 300px;
		max-width: none;
	}

	.af-view .faq-question-right {
		width: 160px;
	}

	.af-view .faq-question-right-value {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: right;
	}

	.af-view .slide-image {
		overflow: visible;
		width: 100%;
		height: 180px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.af-view .workflow-grid {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.af-view .workflow-grid-item {
		max-width: 80%;
		margin-right: auto;
		margin-left: auto;
	}

	.af-view .slide {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.af-view .dashed-line.dashed-line-1 {
		display: none;
	}

	.af-view .dashed-line.dashed-line-2 {
		display: none;
	}

	.af-view .dashed-line.dashed-line-3 {
		display: none;
	}

	.af-view .image-8 {
		height: 20px;
	}

	.af-view .tablet-image {
		top: 28px;
		display: none;
	}
	.faq-question-text-full {
		max-width: 100% !important;
	}
}

.af-view #w-node-_07104b23-ec5c-470f-a454-23624e889eed-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_96e16eac-5115-6b84-f197-7ff6db10011b-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_40fd9f8d-4fcc-cbe0-56d0-a599b1618335-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-b7b08306-e02a-af7c-e7c0-6c37ee75bb6a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_271ec7a3-f07f-e6fc-4fd3-550e712839f3-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_3d6a0472-74f0-5138-312c-5b57399c74c9-ff0fd418 {
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-column-align: auto;
	justify-self: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
}

.af-view #w-node-_69fe0a45-fe0c-d6a2-f0a4-386c43b889db-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
	-ms-grid-column-align: stretch;
	justify-self: stretch;
}

.af-view #w-node-_42e72d28-e75a-c372-06fd-73f000c0f57e-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
	-ms-grid-column-align: stretch;
	justify-self: stretch;
}

.af-view #w-node-_6a5f4bbf-b306-5ab3-5a18-4ed04e5e17b2-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
	-ms-grid-column-align: stretch;
	justify-self: stretch;
}

.af-view #w-node-_9b40ae57-8627-347b-3ff8-975083c0424a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
	-ms-grid-column-align: stretch;
	justify-self: stretch;
}

.af-view #w-node-a15ad687-7a60-33e0-b6cd-13581cab91ed-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: auto;
	justify-self: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
}

.af-view #w-node-a15ad687-7a60-33e0-b6cd-13581cab920d-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_40d13eaf-1f8f-ed82-18b9-1a9a84fef63b-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: auto;
	justify-self: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
}

.af-view #w-node-_19e684ad-181f-c73f-6bb8-462c3a04f1bd-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: auto;
	justify-self: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
}

.af-view #w-node-_103f8cde-c060-98e6-e9d4-08629dae98c2-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_09caabf0-f4de-4f71-8a4f-e0d15cbca0ca-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_93acb37d-28b7-2515-68c9-d5d73bc236ad-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-cef1a724-168c-d9da-5489-2d88d78a0160-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-cdf65033-fe67-042f-ad1e-e8a98a17e430-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_66c6d889-8dc5-8968-cc49-ef63d94f2843-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_94c46fe6-6c03-6fbd-703e-13748a84829c-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1eec-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef1-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_2f3d6414-6edd-2c48-fd40-c00f6a933083-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-a6e34e4d-5ba4-e03c-89df-c6f6a2f43e25-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-cdd3ba07-ce81-699b-bb96-f4cfeaba902f-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce751-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #jmeno-3.w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce75a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce75b-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #prijmeni-2.w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce764-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce765-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #telefon-3.w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce76e-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce76f-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #Email-3.w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce778-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
}

.af-view #w-node-e258aedf-aac8-0465-3915-57d19260b7c9-ff0fd418 {
	-ms-grid-column-align: end;
	justify-self: end;
}

.af-view #w-node-_3b3f3cf5-4ec2-a790-27ec-d8a515bce77e-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a08-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #jmeno-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a11-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a12-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #prijmeni-2.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a1b-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a1c-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #telefon-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a25-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a26-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #Email-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a2f-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a30-ff0fd418 {
	-ms-grid-column-align: end;
	justify-self: end;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a35-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
}

.af-view #w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b4738-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b4739-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b473a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-cd0a4c9e-4cdd-dc7d-b86c-701a9d6b473b-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_7d1f7c1d-a615-71f4-eb5f-a01936420cd3-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
}

.af-view #w-node-_66bbbe6f-0d33-cc6c-a655-c9522da047cb-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_8f841688-0e13-e06e-c90f-d3ba98a7bd6e-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
}

.af-view #w-node-_5126b11b-ce07-37ac-1299-5924b752cbfd-ff0fd418 {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-e39f08cc-9ee5-9e0b-ddc4-433fa21a8d69-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
}

.af-view #w-node-_4f64c683-ba61-19eb-7023-59fb12eb857a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-_549a52d5-6514-6f3c-995b-4d798810d08a-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
}

.af-view #w-node-_3d2cff9a-dc77-6819-ff80-baee1aa69113-ff0fd418 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-row-align: center;
	align-self: center;
}

.af-view #w-node-b9067f31-9156-b701-ba3b-45a66c638867-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_4598aed1-de80-3deb-a433-72b6a41611e9-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-e53baccb-aad3-efb2-a30a-6bde6df2f581-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-eda47b89-afca-c196-06d4-bdebe9433f4b-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_5226ec36-fb86-8555-727d-59a714c9af70-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_9556ba17-0b28-e0cb-cde9-b601395be1c9-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_8836d8c1-6b85-0e8c-14c2-e1d2df177d99-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1c2-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1c3-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1c5-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1c8-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1cb-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1ce-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

.af-view #w-node-_53e45305-c862-7e30-3058-fa7efac0e1d1-24409e3c {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
	.af-view #w-node-a15ad687-7a60-33e0-b6cd-13581cab91ed-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	}

	.af-view #w-node-a15ad687-7a60-33e0-b6cd-13581cab920d-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	}

	.af-view #w-node-_40d13eaf-1f8f-ed82-18b9-1a9a84fef63b-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}

	.af-view #w-node-_19e684ad-181f-c73f-6bb8-462c3a04f1bd-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}
}

@media screen and (max-width: 767px) {
	.af-view #w-node-_3d6a0472-74f0-5138-312c-5b57399c74c9-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}

	.af-view #w-node-_69fe0a45-fe0c-d6a2-f0a4-386c43b889db-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	}

	.af-view #w-node-_42e72d28-e75a-c372-06fd-73f000c0f57e-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-row: 3;
		grid-row-start: 3;
	}

	.af-view #w-node-_3e4f5339-9409-389c-a613-9b216d7504b1-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-row: 4;
		grid-row-start: 4;
	}

	.af-view #w-node-_6b8b1d89-063a-94bf-5b49-c1447aaeac50-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 7;
		-ms-grid-row: 6;
		grid-row-start: 6;
	}

	.af-view #w-node-_6a5f4bbf-b306-5ab3-5a18-4ed04e5e17b2-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-row: 5;
		grid-row-start: 5;
	}

	.af-view #w-node-_9b40ae57-8627-347b-3ff8-975083c0424a-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 8;
		-ms-grid-row: 7;
		grid-row-start: 7;
	}

	.af-view #w-node-_9bb47f99-7276-2a22-10fc-e4eee0742539-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 9;
		-ms-grid-row: 8;
		grid-row-start: 8;
	}

	.af-view #w-node-_2f3d6414-6edd-2c48-fd40-c00f6a933083-ff0fd418 {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	.af-view #w-node-a6e34e4d-5ba4-e03c-89df-c6f6a2f43e25-ff0fd418 {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	.af-view #w-node-cdd3ba07-ce81-699b-bb96-f4cfeaba902f-ff0fd418 {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	.af-view #w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef6-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	}

	.af-view #w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef9-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}

	.af-view #w-node-d93ae43c-6162-49df-cacd-24af8ddf8a98-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
		-ms-grid-row: 3;
		grid-row-start: 3;
	}

	.af-view #w-node-_8d38dbec-c80b-db89-9df6-403095b870fa-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 5;
		-ms-grid-row: 4;
		grid-row-start: 4;
	}

	.af-view #w-node-_0823fe17-3824-8dde-49d4-38e47d0c8c36-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 6;
		-ms-grid-row: 5;
		grid-row-start: 5;
	}

	.af-view #w-node-e258aedf-aac8-0465-3915-57d19260b7c9-ff0fd418 {
		-ms-grid-column-align: center;
		justify-self: center;
	}

	.af-view #jmeno-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a11-ff0fd418 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
	}

	.af-view #prijmeni-2.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a1b-ff0fd418 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
	}

	.af-view #telefon-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a25-ff0fd418 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
	}

	.af-view #Email-3.w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a2f-ff0fd418 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
	}

	.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a30-ff0fd418 {
		-ms-grid-column-align: center;
		justify-self: center;
	}

	.af-view #w-node-c0fe02ec-3cdd-b863-3cee-c084cd337a35-ff0fd418 {
		-ms-grid-column-align: center;
		justify-self: center;
	}
}

@media screen and (max-width: 479px) {
	.af-view #w-node-_66c6d889-8dc5-8968-cc49-ef63d94f2843-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: start;
		justify-self: start;
	}

	.af-view #w-node-_94c46fe6-6c03-6fbd-703e-13748a84829c-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}

	.af-view #w-node-_03003ab7-e5dc-7c9c-a7d9-0048687f20d4-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	}

	.af-view #w-node-_03003ab7-e5dc-7c9c-a7d9-0048687f20d7-ff0fd418 {
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
	}

	.af-view #w-node-_427e7042-8e6d-66a0-cff5-3b70e76e1ef6-ff0fd418 {
		-ms-grid-column-align: start;
		justify-self: start;
	}
}
